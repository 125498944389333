import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAnalytics(ctx, params) {
      return useJwt.getAdminTenantAnalytics(params).then(response => response);
    },
    fetchTenants(ctx, params) {
      return useJwt.getAdminTenants(params).then(response => response);
    },
    getTenantDetail(ctx, id) {
      return useJwt.getAdminTenantDetail(id).then(response => response);
    },
    createTenant(ctx, data) {
      return useJwt.createTenant(data).then(response => response);
    },
    updateTenant(ctx, data) {
      return useJwt.updateTenant(data).then(response => response);
    },
    importTenants(ctx, data) {
      return useJwt.importTenants(data).then(response => response);
    },
    exportTenants(ctx, data) {
      return useJwt.exportTenants(data).then(response => response);
    },
    deleteTenants(ctx, data) {
      return useJwt.deleteTenants(data).then(response => response);
    },
  },
};
