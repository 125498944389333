import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import tenantStoreModule from '../tenantStoreModule';

export default function useTenantDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'tenant-admin';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tenantStoreModule);
  }

  const toastification = toast();
  const refModalDeleteCashbook = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const tenantId = computed(() => props.tenantId);

  const fetchTenantDetail = () => {
    if (tenantId.value) {
      isLoading.value = true;
      store.dispatch('tenant-admin/getTenantDetail', tenantId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchTenantDetail();
  };

  watch(tenantId, val => {

  });

  return {
    refModalDeleteCashbook,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchTenantDetail,
    onOpen,
    isLoading,
  };
}
